/**
 * Load IE 11 separate stylesheet.
 */

if ( 'IE' === device.browser ) {
    var head = document.getElementsByTagName('HEAD')[0];  
    
    var link = document.createElement('link'); 
    var href = wp_head.stylesheet_directory_uri + 'dist/css/ie.min.css';
    if ( wp_head.manifest ) {
    	href = wp_head.manifest['css/ie.min.css'];
    }

    link.rel = 'stylesheet';  
    link.type = 'text/css'; 
    link.href = href;

    head.appendChild(link); 
}
